import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
// import "./mini.css"

// import { Adapter } from "./src/components/Builder/Components/LoginAdapter/Adapter"
const React = require("react")
export const wrapPageElement = ({ element, props }) => {
  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

// const appendThirdPartyScripts = () => {
//   appendScript(
//     `
// console.log('hello)`,
//     "src"
//   )

//   sessionStorage.setItem("loadedScripts", true)
// }
// const appendScript = (scriptInnerHTML, type, load) => {
//   // const titleElement = document.createElement("title")
//   // for (let i = 0; i < Meta.length; i++) {
//   //   if (Meta[i].value) {
//   //     let metaElement = document.createElement("meta")
//   //     metaElement[Meta[i]?.type || "name"] = Meta[i].name
//   //     metaElement.content =
//   //       Meta[i].name === "keywords"
//   //         ? Meta[i].value?.length > 0 &&
//   //           Meta[i].value?.map(phrase => phrase.phrase)?.join(",")
//   //         : Meta[i].value
//   //     document.head.appendChild(metaElement)
//   //   }
//   // }
//   // if (JSONLD) {
//   //   const jsonLdElement = document.createElement("script")
//   //   jsonLdElement.textContent = JSONLD ? `${JSON.stringify(JSONLD)}` : ""
//   //   jsonLdElement.type = "application/ld+json"
//   //   document.head.appendChild(jsonLdElement)
//   // }
//   // titleElement.innerHTML = Title
//   // document.head.appendChild(titleElement)
//   // const scriptElement = document.createElement("script")
//   // scriptElement[type] = scriptInnerHTML
//   // scriptElement[load === "defer" ? "defer" : "async"] = true
//   // document.head.appendChild(scriptElement)
// }
// const loadedScripts = sessionStorage.getItem("loadedScripts")
// const appendScriptsOnScrollOptions = { once: true }

// export const onInitialClientRender = () => {
//   if (true) {
//     window.addEventListener(
//       "load",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//   } else {
//     appendThirdPartyScripts()
//   }
// }

// export const onRouteUpdate = ({ prevLocation }) => {
//   if (prevLocation) {
//     window.removeEventListener(
//       "load",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//     // appendThirdPartyScripts()
//   }
// }
